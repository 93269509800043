<template>
  <!-- Send token  -->
  <div class="send-token">
    <!-- Process -->
    <div class="process">
      <form class="form">
        <!-- Form control  -->
        <div class="form-control">
          <!-- label  -->
          <label>Do you want to sign or check a message?</label>
          <!-- Select  -->
          <select class="select" v-model="Type">
            <!-- Option one -->
            <option value="sign">Sign</option>
            <!-- Option two -->
            <option value="check">check</option>
          </select>
        </div>
        <!-- Form control  -->
        <div class="form-control">
          <!-- Label  -->
          <label>Input Message here</label>
          <!-- Drag & drop   -->
          <div id="drop_zone">
            <textarea class="input-message" rows = "5" v-model="message"></textarea>
          </div>
        </div>
        <!-- Form control  -->
        <div class="form-control">
          <!-- Label  -->
          <label v-if="Type === 'check'">Enter signed</label>
          <label v-else>Your signed message</label>
          <!-- Input  -->
          <textarea v-if="Type === 'check'" v-model="signedMessage"/>
          <textarea v-else v-model="signedMessage" disabled/>
          <!--              <div v-else>-->
          <!--                <p style="padding-right: 5px; padding-left: 5px">{{signedMessage}}</p>-->
          <!--              </div>-->
        </div>
        <!-- Form control  -->
        <div class="form-control">
          <!-- Form groups  -->
          <div class="form-groups">
            <!-- Group two  -->
            <div class="group-two">
              <!-- Label  -->
              <label v-if="Type === 'check'">Enter signer wallet address</label>
              <label v-else>You signed with wallet address</label>
              <!-- Input  -->
              <input v-if="Type === 'check'" type="text" v-model="signerAddress"/>
              <div v-else>
                <p style="padding-right: 5px; padding-left: 5px">{{signerAddress}}</p>
              </div>
            </div>
            <div v-if="correctSigner" class="success">
              <h2>Success</h2>
            </div>
            <div v-if="correctSigner === false" class="error">
              <h2>Not correct Signer Address</h2>
            </div>
          </div>
        </div>
        <!-- Form control  -->
        <div class="form-control">
          <!-- NExt btn  -->
          <button @click.native.prevent="checkData()" class="next-btn">{{Type}}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// Importing things we need
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import {ethers} from 'ethers';
// Images
import metaMaskGr from "../assets/images/metamask-gr.svg";
import shareGr from "../assets/images/share-gr.svg";
import uploadGr from "../assets/images/upload-gr.svg";

import metaMask from "../assets/images/metamask.svg";
import share from "../assets/images/share.svg";
import upload from "../assets/images/upload.svg";
export default {
  name: "SendToken",
  emits: ["setWallet"],
  data() {
    return {
      message: '',
      Type: 'sign',
      signerAddress: '',
      signedMessage: '',
      correctSigner: null
    };
  },
  methods: {
    async signMessage() {
      try {
        if (!window.ethereum) {
          alert("No crypto wallet found. Please install it.");
        }
        await window.ethereum.send("eth_requestAccounts");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        this.signedMessage = await signer.signMessage(this.message);
        this.signerAddress = await signer.getAddress();
      } catch (err) {
        console.log("something when signing message went wrong")
      }
    },
    async verifyMessage() {
      try {
        console.log(this.signedMessage, this.message)
        const signerAddr = await ethers.utils.verifyMessage(this.message, this.signedMessage);
        if (signerAddr !== this.signerAddress) {
          console.log("wrong message", signerAddr)
          this.correctSigner = false;
          return
        }
        console.log("correct message")
        this.correctSigner = true;
      } catch {
        alert("Error verifying");
        this.correctSigner = false;
      }
    },
    checkData() {
      if(this.Type === "check") {
        console.log("check")
        this.verifyMessage()
      } else {
        console.log("sign")
        this.signMessage();
      }
    }
  },
  watch: {
    Type() {
      this.correctSigner = null
    }
  }
};
</script>

<style lang="scss" scoped>
// send-token styles
.send-token {
  // process styles
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: 10px;
  position: relative;
  .process {
    max-width: 1000px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    min-height: 500px;
    width: 100%;
    // process-ul
    .process-ul {
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: space-evenly;
      padding: 25px;
      list-style: none;
      gap: 10px;
      // process-item
      .process-item {
        text-align: center;
        display: block;
        width: 50%;
        .process-title {
          font-weight: bold;
          padding: 10px 0px;
          font-size: 1.2rem;
          color: rgb(173, 169, 169);
        }
        span {
          font-size: 1rem;
          text-transform: capitalize;
          color: #000;
          display: block;
          text-align: center;
        }
        img {
          width: 20%;
        }
      }
      hr {
        width: 100%;
        background: transparent linear-gradient(90deg, #03b3e7 0%, #02e9b5 100%)
        0% 0% no-repeat padding-box;
        height: 3px;
        border: 0px;
      }
    }
  }
  .success-modal {
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .wraper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 20px;
      min-width: 250px;
      min-height: 300px;
      max-width: 400px;
      border: 1px solid #000;
      margin: 10px;
      border-radius: 10px;
      .heading,
      .sub-heading {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 10px;
      }
      .see-btn {
        background: #30d0a5;
        padding: 10px 20px;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: capitalize;
        color: #fff;
        border: 0px;
        border-radius: 15px;
        cursor: pointer;
        margin-top: 40px;
      }
    }
  }
}

.ZoneSpinner {
  text-align: center;
}

.ZoneSpinner .spinner-border {
  width: 5rem;
  height: 5rem;
  color: #18E5E7;
}
.form {
  max-width: 640px;
  margin: auto;
  padding: 0px 10px;
  min-height: 400px;
  // form-control styles
  .form-control {
    min-width: 100%;
    // label styles
    label {
      display: block;
      font-size: 1rem;
      font-weight: bold;
      padding: 5px 10px;
    }

    // select styles
    .select {
      min-width: 100%;
      padding: 6px 10px;
      border: 1px solid #222;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
    }

    // textarea styles
    textarea {
      min-width: 100%;
      padding: 5px 5px;
      border: 1px solid #222;
      border-radius: 5px;
      min-height: 60px;
    }

    // form-groups styles
    .form-groups {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
      gap: 20px;

      // success style
      .success {
        min-width: 50%;
        margin-top: 35px;
        color: #30d0a5;
        h2 {
          height: 25px;
        }
      }

      // error style
      .error {
        min-width: 50%;
        margin-top: 35px;
        color: red;
        h2 {
          height: 25px;
        }
      }

      // group-two style
      .group-two {
        min-width: 50%;
        // input styles
        input {
          min-width: 100%;
          padding: 5px 0px;
          border: 1px solid #222;
          border-radius: 5px;
          text-transform: capitalize;
          text-indent: 10px;
        }
        // div styles
        div {
          //min-width: 93%;
          padding: 5px 0px;
          border: 1px solid #222;
          border-radius: 5px;
          min-height: 30px;
        }
      }
    }

    // sub-heading styles
    .sub-heading {
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 10px;

      a {
        color: #000;
        font-weight: bold;
      }
    }

    // input-message styles
    .input-message {
      padding: 10px;
      border: 1px solid #222;
      border-radius: 5px;
      min-height: 150px;
      width: 100%;
      input {
        //display: none;
      }
    }

    // next-btn styles
    .next-btn {
      background: #30d0a5;
      padding: 5px 40px;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: capitalize;
      color: #fff;
      margin: 30px 0px;
      border: 0px;
      border-radius: 10px;
      cursor: pointer;
    }

    // table style
    .table-border {
      display: grid;
      overflow-y: auto;
      //place-items: top;
      padding: 0;
      border: 1px solid #222;
      border-radius: 5px;
      height: 200px;
    }

    .token-table {
      border-collapse: collapse;
      margin: 0;
      font-size: 0.9em;
      width: 100%;

      thead tr {
        background-color: #222;
        color: #ffffff;
        text-align: left;
        font-weight: bold;
      }

      th, td {
        padding: 8px 10px;
      }

      tbody {
        overflow-y: scroll;
        max-height: 200px;
      }

      tbody tr {
        border-bottom: 1px solid #dddddd;
      }
      tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
      }
    }
  }
}

@media only screen and(max-width: 500px) {
  // send-token styles
  .send-token {
    // process styles
    .process {
      .form {
        // form-control styles
        .form-control {
          // form-groups styles
          .form-groups {
            flex-direction: column;
            // group-one style
            .group-one {
              min-width: 100%;
            }

            // group-two style
            .group-two {
              min-width: 100%;
              // input styles
              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and(max-width:640px) {
  // send-token styles
  .send-token {
    // process styles
    .process {
      padding: 20px 0px;

      // process-ul
      .process-ul {
        display: flex;
        align-items: center;
        list-style: none;
        justify-content: space-evenly;
        padding: 10px;
        list-style: none;
        // process-item
        .process-item {
          img {
            width: 30px;
          }
        }
      }
    }
  }
}

.active {
  color: #000 !important;
}
</style>
